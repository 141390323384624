<template>
	<div class="outer-wrapper filters"> 

		<div id="top-nav"> 
			<router-link :to="{ name: 'Flights Filters' }">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>
	
		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->

			<div class="heading-section">
				<img class="icon" width="19px" src="@/assets/filters/stops.png" />
				<h1 class="heading6">Stops</h1>
			</div>
			<div class="options">
				<div class="option" v-for="(option, index) in filterOptions.stops" :key="option.stops">
					<div class="main-text">
						<div v-if="option.stops == 0" class="title">Direct</div>
						<div v-else-if="option.stops == 1" class="title">{{ option.stops }} stop </div>
						<div v-else class="title">{{ option.stops }} stops </div>

						<div class="sub">
							<span>from £{{option.fromPrice}}</span>
						</div>
					</div>

					<Toggle v-model="searchFilters.stops[index]" class="toggle" />
				</div>
			</div>
		</div>
	
		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Flights Filters' }">
				<button class="button">Continue</button>
			</router-link>
		</div>
	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import Toggle from '@/components/Toggle.vue';
import router from '@/router';

export default {
    data () {
        return {
		   values: {},
        }
	},
	components: {
		Toggle,
	},
   computed: {
		...mapState({
			searchResults: state => state.flightsSearch.searchResults,
			searchFilters: state => state.flightsSearch.searchFilters,
		}),
		...mapGetters({
			filterOptions: 'flightsSearch/getFilterOptions',
		})
	},
    methods: {
		
	},
	created () {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Flights Results',
			})
		}
	},
	
};
</script>


<style scoped>
	.heading-section {
		text-align: left;
	}
	.options {
		margin-top: 20px;
	}
	.option {
		margin-bottom: 15px;
		position: relative;
	}
	.option .toggle {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	.toggle {
		float: right;
	}
</style>