<template>
	<div>
		<div class="toggle-wrapper">
			<label class="toggle">
				<input type="checkbox" ref="checkbox" :checked="index ? value[index] : value" @change="handleChange($event)">
				<span class="round-slider" ></span>
				<span class="text" v-if="text != ''">{{ text }}</span>
			</label>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				default: false,
			},
			index: { // Used to define which property of the value object should be assigned. Optional. If not supplied, value is used as is.
				type: String,
				default: "",
			},
			text: {
				type: String,
				default: "",
			},
			width: {
				type: Number,
				default: 100
			}
		},
		computed: {
			
		},
		data() {
			return {

			}
		},
		methods: {
			click() { // Used in parent component to trigger click event on the checkbox. Used for a hacky fix for reactivity (see FlightsFiltersAirlines.vue)
				this.$refs.checkbox.click();
			},
			handleChange(event) {
				// If index is not set, just emit the value
				if(this.index == "") {
					this.$emit('input', event.target.checked);
					return;
				}
				// Otherwise, emit the value object with the along with the index property that should be set
				this.$emit('input', { ...this.value, [this.index]: event.target.checked });
			}
		}
	}
</script>

<style scoped>
	/* Toggle stuff */
	.toggle-wrapper {
		display: inline-block;
		width: 100%;
	}
	.toggle-wrapper .toggle {
		width: 100%;
	}
	.toggle-wrapper.loading {
		opacity: 0.3;
		pointer-events: none;
	}
	.toggle-wrapper .toggle {
		position: relative;
		display: inline-block;
	}
	.toggle-wrapper .toggle input {
		opacity: 0;
		width: 0;
		height: 0;
		position: absolute;
	}
	.round-slider {
		cursor: pointer;
		width: 40px;
		border-radius: 15px;
		background-color: #979797;
		-webkit-transition: .4s;
		transition: .4s;
		width: 40px;
		height: 20px;
		display: inline-block;
		vertical-align: middle;
		transform-origin: 0 0;
	}
	.round-slider:before {
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
		left: 2px;
		bottom: 2px;
		background-color: #ffffff;
		border-radius: 50%;
		-webkit-transition: .4s;
		transition: all  .4s ease;
	}
	.toggle-wrapper .toggle input:checked + .round-slider {
		background-color: #118AB9;
	}
	.toggle-wrapper .toggle input:checked + .round-slider:before {
		-webkit-transform: translateX(20px);
		-ms-transform: translateX(20px);
		transform: translateX(20px);
	}
	.text {
		margin-left: 6px;
	}
</style>